@import "../../utils/css/variables.module";
@import "../../utils/css/mixins";

.contacts {
  background: darken($dark-color, 3);
  min-height: 500px;
  display: flex;
}

.contacts__inner {
  display: flex;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.contacts__info {
  width: 30%;
  min-width: 390px;
  padding: 2rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    width: 100%;
    min-width: 0;
    padding: 1rem;
    font-size: 1.2rem;
  }
}

.contacts__logo {
  height: 4rem;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    height: 3rem;
    margin-bottom: 20px;
  }

  svg {
    height: 100%;
    max-width: 100%;
    fill: $main-app-color;
  }
}

.contacts__info-unit {
  display: flex;
  align-items: center;
  height: 3rem;

  @media (max-width: 767px) {
    height: 2.5rem;
  }
}

.contacts__info-unit_clickable {
  color: $light-color;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $main-app-color;
  }

  svg {
    display: flex;
  }
}

.contacts__info-unit-icon {
  margin: 3px 20px 0 0;

  @media (max-width: 767px) {
    margin: 2px 10px 0 0;
  }
}

.contacts__map {
  width: 70%;
  height: 100%;

  @media (max-width: 767px) {
    height: calc(100vh - #{$header-height});
    width: 100%;
  }
}

.contacts__map-iframe {
  height: 400px;
  width: 600px;
  max-width: 600px;
  max-height: 80vh;

  @media (max-width: 767px) {
    width: 90vw;
  }
}
