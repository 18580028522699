// @import "../../utils/css/variables";
@import "../../utils/css/variables.module";
@import "../../utils/css/mixins";

.why-us__inner {
  @include section;
  flex-direction: column;
}

.why-us__heading {
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: $main-app-color;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
}

.why-us__subheading {
  text-transform: uppercase;
  text-align: center;
  margin: 0.5rem 0;
}

.why-us__cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

.why-us__card {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 30%;
  max-width: 300px;
  text-align: center;
  margin: 1rem;
  border: 1px solid $light-color;
  border-radius: $border-radius;
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.why-us__card-title {
  padding: 0.5rem;
  background: $app-black-color;
  font-size: 1.5rem;
  color: $light-color;
  text-transform: uppercase;
}

.why-us__card-img {
  max-width: 90%;
  height: 200px;
  margin: 1rem auto;

  svg {
    width: 100%;
    height: 100%;
    fill: $light-color;
  }
}

.why-us__card-desc {
  line-height: 1.3rem;
  padding: 1rem 0.5rem;
  // border-top: 1px solid $light-color;
  background: $app-black-color;
  flex: 1;
}
