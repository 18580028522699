@import "../../utils/css/variables.module";

.calculator-tables {
  width: 100%;
  display: flex;
  justify-content: space-around;
  overflow: hidden;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }

  h6 {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 1.15rem;
  }
}

.calculator-tables__table {
  // border: 1px solid $app-grey-color;
  border-radius: $border-radius;
  // overflow: hidden;

  &:first-child {
    margin-right: 1rem;
  }

  @media (max-width: 992px) {
    min-width: 50%;
    margin-bottom: 1rem;

    &:first-child {
      margin-right: 0;
    }
  }

  table {
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 1.3rem;

    @media (max-width: 1200px) {
      font-size: 1.1rem;
    }
    @media (max-width: 992px) {
      font-size: 1rem;
    }
    @media (max-width: 767px) {
      font-size: 0.8rem;
    }
  }

  th {
    background: $app-black-color;
    padding: 1rem;

    @media (max-width: 767px) {
      font-size: 0.8rem;
    }
    @media (max-width: 550px) {
      font-size: 0.7rem;
    }
  }

  td {
    padding: 0.5rem;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      height: 1rem;
      margin-right: 0.5rem;
    }
  }

  td,
  th {
    vertical-align: middle;
    border-right: 1px solid $app-grey-color;

    &:last-child {
      border-right: 0;
    }

    @media (max-width: 550px) {
      padding: 0.5rem 0.25rem;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background: lighten($dark-color, 8);
      }
      &:nth-child(odd) {
        background: lighten($dark-color, 2);
      }

      @mixin hover {
        background: darken($main-app-color, 30);
      }
      &:active {
        @include hover;
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          @include hover;
        }
      }
    }

    td {
      text-align: center;
    }
  }
}
