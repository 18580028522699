// @import "../../utils/css/variables";
@import "../../utils/css/variables.module";
@import "../../utils/css/mixins";

.hero {
  padding-top: $header-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(../../assets/imgs/hero-bg.jpg) center no-repeat;
  background-size: cover;
  // background-size: auto 100vh;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: url(../../assets/imgs/bg_point.png) rgba(0, 0, 0, 0.5);
  }
}

.hero__inner {
  @include section;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.hero__text {
  position: relative;
  width: 100%;
  padding: 1rem;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba($dark-color, 0.4);

  @media (max-width: 767px) {
    padding: 0.5rem;
  }

  h4 {
    font-size: 5rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
    font-family: "Terminator Gen", sans-serif;

    @media (max-width: 767px) {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }

  p {
    font-size: 2rem;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
}

.hero__btn {
  @include button;
  margin-top: 2rem;
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
}
