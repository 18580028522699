// @import "../../utils/css/variables";
@import "../../utils/css/variables.module";
@import "../../utils/css/mixins";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height;
  z-index: $header-z-i;
  color: $dark-color;
  background: $main-app-color;
}

.header__inner {
  @include app-inner-width;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.header__logo {
  height: 70%;
  cursor: pointer;

  @media (max-width: 1200px) {
    height: 60%;
  }
  @media (max-width: 992px) {
    height: 29%;
  }
  @media (max-width: 767px) {
    margin: 0;
  }

  svg {
    height: 100%;
  }
}

.header__menu {
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 0 5vw 0 2vw;
  font-size: 1.2rem;
  font-weight: bold;

  @media (max-width: 1200px) {
    margin: 0 2vw 0 2vw;
    font-size: 1rem;
  }
  @media (max-width: 992px) {
    font-size: 0.85rem;
    // display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.header__menu-item {
  // display: flex;
  // text-align: center;
  // align-items: center;
  text-transform: uppercase;
  margin: 0 15px;
  cursor: pointer;
  transition: $main-transition;

  @mixin hover {
    color: $app-grey-color;
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}

.header__menu-btn {
  display: none;
  position: relative;
  height: 1.5rem;
  width: 2rem;
  margin-left: auto;
  font-size: 2rem;
  cursor: pointer;
  transition: $menu-btn-transition;

  @media (max-width: 767px) {
    display: block;
  }

  @mixin hover {
    .header__menu-btn-line {
      background: $app-grey-color;
    }
  }
  &:active {
    @include hover;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}

.header__menu-btn-line {
  position: absolute;
  top: 10px;
  width: 100%;
  height: 6px;
  background: $dark-color;
  border-radius: 3px;

  &:first-child {
    top: 0px;
  }
  &:last-child {
    top: 20px;
  }

  &:first-child,
  &:last-child {
    // transition-property: top, transform;
    // transition-duration: 0.3s, 0.3s;
    // transition-delay: 0s, 0.3s;
    transition: background-color 0.15s linear, top 0.15s linear 0.15s, transform 0.15s linear 0s;
  }
  &:nth-child(2) {
    transition: background-color 0.15s linear, opacity 0s linear 0.2s;
  }
}

.header__menu-btn_close {
  .header__menu-btn-line {
    &:first-child {
      top: 10px;
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:last-child {
      top: 10px;
      transform: rotate(-45deg);
    }

    &:first-child,
    &:last-child {
      transition: background-color 0.15s linear, top 0.15s linear 0s, transform 0.15s linear 0.15s !important;
    }
  }
}
