// @import "./variables";
@import "./variables.module.scss";

@mixin app-inner-width {
  width: $app-inner-width;
  max-width: 1200px;
}

@mixin section {
  @include app-inner-width;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 1rem 0.5rem;
  }
}

@mixin button {
  line-height: normal;
  background: $main-app-color;
  border-radius: $border-radius;
  padding: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: $main-transition;

  &:active {
    background: darken($main-app-color, 30);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: darken($main-app-color, 30);
    }
  }
}

@mixin modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $dark-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin input {
  width: 250px;
  height: 50px;
  background: $app-black-color;
  color: $light-color;
  border: 1px solid $app-grey-color;
  border-radius: $border-radius;
  transition: $main-transition;
}
